import { axiosInstance } from "../../axios";

const POSTCreateAdvertisement = "api/EventAdvertisement/CreateAdvertisementByCompany";
const GETSearchAdvertismentByLocation = "api/EventAdvertisement/SearchAdvertisment";
const GETSearchAllAdvertisment = "api/EventAdvertisement/SearchAllAdvertisment";
const GETSearchPastAdvertismentByLocation = "api/EventAdvertisement/SearchPastAdvertisment";
const GETSearchAdvertismentByKey = "api/EventAdvertisement/SearchAdvertismentByKey/";
const GETViewAdvertismentByKey = "api/EventAdvertisement/ViewAdvertismentByKey/";
const POSTDeleteAdvertisement = "api/EventAdvertisement/DeleteAdvertisement";

const CreateAdvertisement = async function(advertisement) {
  try {
    const response = await axiosInstance.coreAPIInstance.post(POSTCreateAdvertisement, advertisement);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const DeleteAdvertisement = async function(id) {
  try {
    const response = await axiosInstance.coreAPIInstance.delete(`${POSTDeleteAdvertisement}/${id}`);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const searchAll = async function() {
  try {
    const response = await axiosInstance.coreAPIInstance.get(`${GETSearchAllAdvertisment}`);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const searchByLocation = async function() {
  try {
    const response = await axiosInstance.coreAPIInstance.get(`${GETSearchAdvertismentByLocation}`);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const searchPastEventsByLocation = async function() {
  try {
    const response = await axiosInstance.coreAPIInstance.get(`${GETSearchPastAdvertismentByLocation}`);
    return parseList(response);
  } catch (error) {
    return null;
  }
};


const searchByKey = async function(id) {
  try {
    const response = await axiosInstance.coreAPIInstance.get(`${GETSearchAdvertismentByKey}${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const viewByKey = async function(id) {
  try {
    const response = await axiosInstance.coreAPIInstance.get(`${GETViewAdvertismentByKey}${id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const parseError = error => {
  let stringMessage = error.response.data.Errors.join(" <br/> ");
  error.response.data.Message += "<br/>" + stringMessage;
  return error.response.data;
};

const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

export const advertisementData = {
  CreateAdvertisement,
  searchByLocation,
  searchPastEventsByLocation,
  searchByKey,
  viewByKey,
  DeleteAdvertisement,
  searchAll
};